.moduleList {
  list-style: none;
  padding: 0;
  margin: 2em 0 0;
}

.moduleList li {
  margin-bottom: 1em;
}
.moduleList li:last-child {
  margin-bottom: 0;
}

.moduleList h3 {
  font-size: 20px;
  margin: 0;
}

.links {
  margin-top: 1em;
  display: flex;
  column-gap: 1em;
  row-gap: 1em;
  flex-wrap: wrap;
}
.links a {
  display: block;
  text-decoration: none;
}

.tabs ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 1em;
}

.tabs li {
  color: #fff;
  border: 1px solid;
  border-bottom: none;
  position: relative;
  z-index: 100;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-color: rgba(217, 212, 173, 0.3);
  border-radius: 0;
  background: linear-gradient(0deg, rgb(43 43 23), rgba(217, 212, 173, 0.02));
  padding: 0.5em 1em 0.3em;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}

.tabs li:hover {
  filter: brightness(1.3);
}

.tabs li::before {
  inset: 2px;
  bottom: 0px;
  border: 1px solid rgba(217, 212, 173, 0.1);
  border-bottom: none;
  content: '';
  z-index: 1;
  position: absolute;
  pointer-events: none;
}
.tabs li::after {
  content: ' ';
  width: 100%;
  height: 4px;
  background: none;
  position: absolute;
  bottom: -4px;
  left: 0;
}

.tabs li.activeTab:hover {
  filter: brightness(1);
}

.tabs li.activeTab::before {
  bottom: -4px;
}
.tabs li.activeTab::after {
  background: rgb(43 43 23);
}
.toolImage {
  border-radius: 100px;
  overflow: hidden;
}
