@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/RobotoMono/roboto-mono-v13-latin-regular.woff)
      format('woff2'),
    url(./fonts/RobotoMono/roboto-mono-v13-latin-regular.woff2) format('woff');
}

/* spectral-regular - latin */
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/Spectral/spectral-v7-latin-regular.woff) format('woff2'),
    url(./fonts/Spectral/spectral-v7-latin-regular.woff2) format('woff');
}

/* spectral-extra-light - latin */
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 200;
  src: url(./fonts/Spectral/Spectral-ExtraLight.woff) format('woff2'),
    url(./fonts/Spectral/Spectral-ExtraLight.woff2) format('woff');
}

/* spectral-extra-light-italic - latin */
@font-face {
  font-family: 'Spectral';
  font-style: italic;
  font-weight: 200;
  src: url(./fonts/Spectral/Spectral-ExtraLightItalic.woff) format('woff2'),
    url(./fonts/Spectral/Spectral-ExtraLightItalic.woff2) format('woff');
}

html,
body {
  font-family: 'Spectral';
  font-weight: 200;
  color: white;
  min-height: 100vh;
  font-size: 16px;
  letter-spacing: -0.03em;
  margin: 0;
  padding: 0;
  background: black;
}

a {
  color: white;
  cursor: pointer;
  text-decoration: underline;
}

a:hover {
  color: rgba(252, 248, 217, 0.8);
}

* {
  box-sizing: border-box;
}
