.container {
  padding: 10vw 1rem;
  background: url(./assets/firstFrame.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.header {
  display: flex;
  column-gap: 1em;
  row-gap: 1em;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.main {
  min-height: 100vh;
  padding: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h2 {
  margin: 0 0 1rem 0;
}

p {
  font-size: 16px;
  margin: 0;
}

.vidBg video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
}

.content {
  background: rgb(15 15 2 / 84%);
  position: relative;
  z-index: 10;
  max-width: 750px;
}
.socialLink {
  height: 100%;
  display: flex;
  column-gap: 0.5em;
  align-items: center;
  justify-content: center;
  padding: 1em;
  text-decoration: none;
}

.socialLink img {
  display: block;
  height: 100%;
  max-height: 1.5em;
}

h3 {
  font-size: 20px;
  margin: 0;
}

figure {
  margin: 0;
}

.wikiImg {
  width: 100%;
  max-width: 370px;
  margin: 0 auto;
}
.wikiImg img {
  display: block;
  width: 100%;
  opacity: 0.7;
}

.wikiImg figcaption {
  margin-top: 0.5em;
}

.viriditas {
  margin: 1em 2em;
  padding: 0.5em 0.5em 0.5em 1em;
  border-left: 2px solid seagreen;
  background: #0080002b;
}

.viriditas blockquote {
  margin: 0;
}

.viriditas figcaption {
  margin: 0.5em 0 0 0.5em;
}

.wikiInfo {
  padding: 1em;
  display: flex;
  gap: 1em;
}

.guildInfo {
  width: 68%;
  min-width: 300px;
}

.guildBadge {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

@media (max-width: 700px) {
  .wikiInfo {
    flex-wrap: wrap;
  }
  .header {
    justify-content: center;
  }
}

@media (max-width: 790px) {
  .guildInfo {
    width: 100%;
  }
}
